import React, { createContext,useState,useEffect} from "react";

const userContext = createContext();

const UserProvider = ({children}) => {
    const [theme, setTheme] = useState('light');

    return (
        <userContext.Provider value={{
                                        theme, 
                                        setTheme
                                    }}>
            {children}
        </userContext.Provider>
    );
}
export {
    userContext,
    UserProvider
};